// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'The Story of Aura',
  slug: '/the-story-of-aura',
  abstract:
    'With a circular flow of valuable resources, the team discovered a new way of perception. The movement of freely available resources could be seen as a sustainable cycle and called ‘Circular Basic Income.’',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'The Story of Aura', slug: '/the-story-of-aura' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    launch: file(relativePath: { eq: "launch.jpg" }) {
      ...max900
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper pageSchema={pageSchema} {...props}>
    <div className="container">
      <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
      <Row
        gutter={[
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
          { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
        ]}
        style={{
          borderTop: '4px solid var(--gold2)',
          width: '100%',
          margin: '1.1rem 0rem',
          padding: '0rem',
        }}
      >
        <Col
          xs={24}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          xxl={6}
          style={{ padding: '1.1rem' }}
        >
          <h1 style={{ textAlign: 'right' }}>
            The Story
            <br />
            of Aura
          </h1>
        </Col>
        <Col
          xs={24}
          sm={18}
          md={18}
          lg={18}
          xl={18}
          xxl={18}
          style={{ padding: '1.1rem' }}
        >
          <p>
            In the beginning of 2018, the Korean Pavilion came across a
            revolutionary global potential and contacted Professor Cho at&nbsp;
            <Link to="http://sciencewalden.org/en">
              Science Walden Centre in Korea
            </Link>
            .
          </p>
          <p>
            He had created monetary value out of an under-utilized human
            resource – poop! With a provocative truth, he addressed the divorce
            of humanity from the cycle of nature and life. He discovered a way
            to bring back prosperity into the lives of people.
          </p>
          <p>
            He developed a toilet that would convert a person’s daily feces into
            an asset: compost and biogas. This ‘hi-tech toilet’ gives the users
            credit on their smartphone using the feces Standard Money app for
            every contribution. These virtual credits have the potential to be a
            global currency.
          </p>
          <p>
            Since the hi-tech toilet is not yet at an implementable stage, the
            team looked at this brilliant idea as a metaphor for under-utilized
            resources in the community. We have an abundance of such resources
            in much of what is considered waste, over-production and ‘sloppy
            seconds.’
          </p>
          <p>
            Auroville started a collaborative research with the Science Walden
            Centre to raise human consciousness and call for a new future. With
            international diversity and willing conscious participants,
            Auroville became a perfect place for this vision to become reality.
          </p>
          <p>
            The concept needed some modifications to meet the unique
            requirements of Auroville. The diverse range of relative human needs
            could be met through a daily endowment and interpersonal sharing. To
            break free from illusory security and the ego-centric behavior of
            hoarding, selfishness, and misery; a care economy of sharing became
            the heart of the network.
          </p>
          <p>
            The natural flow of life follows the fundamental law of
            impermanence. You get ‘free’ credits, in the same way nature gives
            you. You ‘share’ and bring joy, just like the cycles of a balanced
            ecosystem. You ‘circulate’ what you don’t use, just like the cycle
            of life and death in nature.
          </p>
          <p>
            With a circular flow of valuable resources, the team discovered a
            new way of perception. The movement of freely available resources
            could be seen as a sustainable cycle and called ‘Circular Basic
            Income.’
          </p>
          <p>
            When we realize the interconnectedness of life, and share, a
            fulfillment naturally arises. The system is a conscious use of
            technology to bring us together, to share the prosperity and
            abundance we already have, but are not aware of.
          </p>
          <p>
            The project team underwent two years of intense collaboration to
            realize the dream of the Aura Network App; to help create for the
            members of the community a sense of belongingness and an opportunity
            to care for each other.
          </p>
        </Col>
      </Row>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
